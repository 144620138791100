import React, {ReactElement, useContext, useState} from "react";
import {CSSTransition} from "react-transition-group";

import styles from "./styles/_BlockTitleCS.module.scss";
import classNames from "classnames";

import {observer} from "mobx-react";
import {StoreContext} from "../../../stores/StoreLoader";
import InlineTextEditor from "../../utilities/InlineTextEditor";
import WatsonApi from "../../../backends/WatsonApi";
import NotificationManager from "../../notifications/NotificationManager";
import {runInAction} from "mobx";
import ViewMoreButton from "./ViewMoreButton";
import {EditDropDownItem} from "./EditDropDown";
import dynamic from "next/dynamic";

const EditDropDown = dynamic(() => import('./EditDropDown'));

const blockTitleClassName = classNames({
    [styles.blockTitle]: true,
});

const blockTitlePlaceholderClassName = classNames({
    [styles.blockTitle]: true,
    [styles.blockTitlePlaceholder]: true,
});

const transitionClassNames = {
    enter: styles.transitionEnter,
    enterActive: styles.transitionEnterActive,
    enterDone: styles.transitionEnterDone,
    exit: styles.transitionExit,
    exitActive: styles.transitionExitActive,
    exitDone: styles.transitionExitDone,
};

type IBlockTitleCSProps = {
    editing?: boolean,
    aboveTheFold: boolean,
    allowSave?: boolean,
    customTitleComponent?: ReactElement<any, any>,
    blockTitleContainerClassName?: string,
    editDropDownClassName?: string,
    dropDownItems?: EditDropDownItem[],
    showViewMore: boolean,
    viewMoreButtonText?: string | ReactElement,
    viewMoreButtonUrl?: string | undefined,
    customButton?: ReactElement<any, any>,
} & ({
    blockObj: IFilesTerraceBlock | IAboutTerrace | IHTmlTerrace | ILinksTerrace | IStatementTerrace | IShortcutsTerrace | IAccordionTerrace | IShopTerrace,
    setEditing: (value: boolean, cancel: boolean) => void,
} | {
    blockObj: ITerrace,
    setEditing?: never,
})

const BlockTitleCS = observer((props: IBlockTitleCSProps) => {
    const {interfaceStore, userStore, gridStore, organizationStore} = useContext(StoreContext);
    const [title, setTitle] = useState(props.blockObj.title);

    const blockTitleContainerClassName = classNames({
        [styles.blockTitleContainer]: true,
        [styles.blockTitleContainerAboveTheFold]: props.aboveTheFold,
        [props.blockTitleContainerClassName || '']: props.blockTitleContainerClassName,
    })

    const editDropDownClassName = classNames({
        [styles.editDropDown]: true,
        [props.editDropDownClassName || '']: props.editDropDownClassName,
    });

    const titleWrapperClassName = classNames({
        [styles.titleWrapper]: true,
    });

    async function saveTitle() {
        if (title !== props.blockObj.title) {
            try {
                const client = await WatsonApi();
                const response = await client.apis.organizations.organizations_terraces_partial_update({
                    organization_pk: props.blockObj.organization_id,
                    id: props.blockObj.id,
                    data: {
                        title,
                    },
                });
                NotificationManager.success("Title successfully saved!");
                runInAction(() => {
                    gridStore.blocks.set(props.blockObj.id, JSON.parse(response.data));
                })
            } catch (e) {
                setTitle(props.blockObj.title);
                NotificationManager.error("Something went wrong! Please try again.");
            }
        }
    }

    return <div className={styles.titleTransitionContainer}>
        <CSSTransition appear classNames={transitionClassNames} in={props.aboveTheFold} timeout={250}>
            <header className={blockTitleContainerClassName}>
                <div className={styles.innerTitleContainer}>
                    <div className={`${styles.titleContainer}`}>
                        <div className={styles.title}>
                            {props.customTitleComponent ?
                                <div className={titleWrapperClassName}>
                                    {props.customTitleComponent}
                                </div> :
                                <div className={titleWrapperClassName}>
                                    {userStore.editor &&
                                        <EditDropDown
                                            className={editDropDownClassName}
                                            blockObj={props.blockObj}
                                            title={title}
                                            dropDownItems={props.dropDownItems}/>
                                    }
                                    <InlineTextEditor
                                        text={props.blockObj.title}
                                        canEdit={userStore.isEditor}
                                        handleTextChange={setTitle}
                                        handleSave={saveTitle}
                                        wrapperClassName={styles.editableTitle}
                                        placeholder={"Enter a Title"}
                                    >
                                        {userStore.isEditor && !props.blockObj.title ? <h2 className={blockTitlePlaceholderClassName}>
                                            Add Title
                                        </h2> : <h2 className={blockTitleClassName}>
                                            {props.blockObj.title}
                                        </h2>}
                                    </InlineTextEditor>
                                </div>}
                        </div>
                        {props.showViewMore &&
                            <div className={styles.viewMoreButton}>
                                <ViewMoreButton
                                    terraceType={props.blockObj.type}
                                    organizationId={organizationStore.currentOrganization.id}
                                    text={props.viewMoreButtonText}
                                    url={props.viewMoreButtonUrl}
                                />
                            </div>
                        }
                        {props.customButton &&
                            <div>
                                {props.customButton}
                            </div>
                        }
                    </div>
                </div>
            </header>
        </CSSTransition>
    </div>;
});

export default BlockTitleCS;
